<template>
  <v-container class="pl-sm-16" fluid>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :loading="api.isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="teal--text"> Users </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogHide" max-width="500px">
            <v-card>
              <v-card-title> Are you sure you want to hide? </v-card-title>
              <v-card-text class="pa-5">
                <div
                  class="grey lighten-5 rounded-lg pa-5 text-uppercase text-h6"
                >
                  {{ selectedItem.name }} -
                  {{ selectedItem.mobile_number }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal darken-1"
                  text
                  @click="dialogHide = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="hide"> Yes </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogActivate" max-width="500px">
            <v-card>
              <v-card-title> Are you sure you want to activate? </v-card-title>
              <v-card-text class="pa-5">
                <div
                  class="grey lighten-5 rounded-lg pa-5 text-uppercase text-h6"
                >
                  {{ selectedItem.name }} -
                  {{ selectedItem.mobile_number }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal darken-1"
                  text
                  @click="dialogActivate = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="activate"> Yes </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDeactivate" max-width="500px">
            <v-card>
              <v-card-title>
                Are you sure you want to deactivate?
              </v-card-title>
              <v-card-text class="pa-5">
                <div
                  class="grey lighten-5 rounded-lg pa-5 text-uppercase text-h6"
                >
                  {{ selectedItem.name }} -
                  {{ selectedItem.mobile_number }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal darken-1"
                  text
                  @click="dialogDeactivate = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="deactivate">
                  Yes
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPassword" max-width="500px">
            <v-card>
              <v-card-title> Change Password </v-card-title>
              <v-card-text>
                <div class="pa-5 text-uppercase my-2">
                  {{ selectedItem.name }} -
                  {{ selectedItem.mobile_number }}
                </div>
                <v-text-field
                  v-model="newPassword"
                  label="New Password (6 digit code)"
                  filled
                  dense
                  counter="6"
                  :rules="rules"
                  maxlength="6"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal darken-1"
                  text
                  @click="dialogPassword = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="changePassword">
                  Change
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAccess" max-width="500px">
            <v-card>
              <v-card-title> Change Access </v-card-title>
              <v-card-text class="pa-5">
                <div class="grey lighten-5 rounded-lg pa-5 text-uppercase">
                  {{ selectedItem.name }} -
                  {{ selectedItem.mobile_number }}

                  <v-radio-group v-model="selectedItem.access" row>
                    <v-radio label="Buyer" value="Buyer"></v-radio>
                    <v-radio label="Seller" value="Seller"></v-radio>
                    <v-radio
                      label="Seller & Buyer"
                      value="Seller & Buyer"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialogAccess = false">
                  Cancel
                </v-btn>
                <v-btn color="teal darken-1" text @click="changeAccess">
                  Save
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
        small
          :color="getColor(item.status)"
          dark
          style="width: 80px"
          class="justify-center"
        >
          {{ item.status }}
        </v-chip>
        <v-btn
          text
          small
          color="red"
          v-if="item.status == 'ACTIVE' && item.access != 'Admin'"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogDeactivate = true;
          "
        >
          Deactivate
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          v-else-if="item.access != 'Admin'"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogActivate = true;
          "
        >
          Activate
        </v-btn>
      </template>
      <template v-slot:item.access="{ item }">
        <v-chip style="width: 120px" class="justify-center" small>
          {{ item.access }}
        </v-chip>

        <v-btn
          text
          small
          v-if="item.status == 'ACTIVE' && item.access != 'Admin'"
          color="grey"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogAccess = true;
          "
        >
          Change
        </v-btn>
      </template>
      <template v-slot:item.change_password="{ item }">
        <v-btn
          text
          small
          v-if="item.access != 'Admin'"
          color="grey"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogPassword = true;
            newPassword = null;
          "
        >
          Change Password
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="red"
          text
          @click="
            selectedItem = item;
            dialogHide = true;
          "
        >
          Hide
        </v-btn>
      </template>

    </v-data-table>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  watch: {
    //
  },

  created() {
    this.initialize();
  },
  props: [
    //
  ],
  data: () => ({
    rules: [
      (v) => !!v || "Code is required",
      (v) => /^\d{6}$/.test(v) || "Code must be exactly 6 digits",
    ],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    newPassword: null,
    timeout: 2000,
    dialogActivate: false,
    dialogDeactivate: false,
    dialogAccess: false,
    dialogPassword: false,
    dialogHide: false,
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Mobile", value: "mobile_number" },
      { text: "Status", value: "status" },
      { text: "Access", value: "access" },
      { text: "Action", value: "change_password", align: "center" },
      { text: "Action", value: "actions", align: "center" },
    ],
    users: [],
    selectedItem: {
      neme: "",
      mobile_number: "",
    },
  }),
  mounted() {
    //
  },
  methods: {
    getColor(status) {
      if (status == "ACTIVE") return "green";
      else if (status == "NEW") return "orange";
      else return "red";
    },
    getAccess(capability_list) {
      let admin = [
        "USERS/CREATE",
        "USERS/READ",
        "USERS/UPDATE",
        "USERS/DELETE",
        "AUCTIONS/CREATE",
        "AUCTIONS/READ",
        "AUCTIONS/UPDATE",
        "AUCTIONS/DELETE",
        "BIDS/CREATE",
        "BIDS/READ",
        "BIDS/UPDATE",
        "BIDS/DELETE",
        "ITEMS/CREATE",
        "ITEMS/READ",
        "ITEMS/UPDATE",
        "ITEMS/DELETE",
        "REVIEWS/CREATE",
        "REVIEWS/READ",
        "REVIEWS/UPDATE",
        "REVIEWS/DELETE",
      ];
      let seller = [
        "BIDS/READ",
        "AUCTIONS/CREATE",
        "AUCTIONS/READ",
        "USERS/READ",
        "ITEMS/READ",
        "AUCTIONS/UPDATE",
      ];
      let buyer = [
        "BIDS/READ",
        "ITEMS/READ",
        "BIDS/CREATE",
        "AUCTIONS/READ",
        "USERS/READ",
      ];
      let seller_and_buyer = [
        "BIDS/READ",
        "AUCTIONS/CREATE",
        "AUCTIONS/READ",
        "USERS/READ",
        "ITEMS/READ",
        "AUCTIONS/UPDATE",
        "BIDS/CREATE",
      ];
      if (admin.every((item) => capability_list.includes(item))) {
        return "Admin";
      } else if (
        seller_and_buyer.every((item) => capability_list.includes(item))
      ) {
        return "Seller & Buyer";
      } else if (seller.every((item) => capability_list.includes(item))) {
        return "Seller";
      } else if (buyer.every((item) => capability_list.includes(item))) {
        return "Buyer";
      } else {
        return "-";
      }
    },
    deactivate() {
      this.api.url = this.$api.servers.auth + "/v1/en/user/lock";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogDeactivate = false;
      };
      this.api.params = {
        mobile_number: this.selectedItem.mobile_number,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
    activate() {
      this.api.url = this.$api.servers.auth + "/v1/en/user/activate";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogActivate = false;
      };
      this.api.params = {
        mobile_number: this.selectedItem.mobile_number,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
    initialize() {
      this.api.url = this.$api.servers.bidding + "/v1/en/admin/user/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.log(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.users = [];
        resp.forEach((element) => {
          this.users.push({
            id: element.id,
            name: element.name,
            mobile_number: element.mobile_number,
            status: element.status,
            access: this.getAccess(JSON.parse(element.capability_list)),
            last_login_at: element.last_login_at,
          });
        });
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
    changePassword() {
      if (/^\d{6}$/.test(this.newPassword)) {
        this.api.url = this.$api.servers.auth + "/v1/en/user/changePassword";

        this.api.callbackReset = () => {
          this.api.isLoading = true;
          this.api.isError = false;
        };

        this.api.callbackError = (e) => {
          this.api.isLoading = false;
          this.api.isError = true;
          this.api.error = e;
        };
        this.api.callbackSuccess = () => {
          this.api.isLoading = false;
          this.initialize();
          this.dialogPassword = false;
        };
        this.api.params = {
          mobile_number: this.selectedItem.mobile_number,
          new_password: this.newPassword,
        };
        this.api.method = "post";
        this.$api.fetch(this.api);
      }
    },
    changeAccess() {
      this.api.url =
        this.$api.servers.bidding + "/v1/en/admin/user/changeAccess";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogAccess = false;
      };
      let _new_role = "";
      if (this.selectedItem.access == "Seller & Buyer") {
        _new_role = "FISHER/BUYER";
      } else if (this.selectedItem.access == "Seller") {
        _new_role = "FISHER";
      } else if (this.selectedItem.access == "Buyer") {
        _new_role = "BUYER";
      }
      this.api.params = {
        mobile_number: this.selectedItem.mobile_number,
        new_role: _new_role,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
    hide() {
      this.api.url = this.$api.servers.bidding + "/v1/en/admin/user/delete";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogHide = false;
      };
      this.api.params = {
        id: this.selectedItem.id,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>